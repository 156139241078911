/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact';
import SizePickerModal from '../components/SizePickerModal';
import { formatId } from '../collection/utils';
import { getVariantInventoryQuantity, isRoundedCorner } from './utils'
import getUrl from '@/scripts/utils/get-url'
import translate from '@/scripts/utils/translate';

var isFirstSizeSelected = 2
const languages = window.pdpLanguages ?? {}

class SizeDropdown extends Component {
	constructor(props) {
		super(props);
		this.selectRef = createRef();

    const currentCountrySizeData = [
      { title: "us_size", text: "United State (US) Sizing" },
      // { title: "aus_size", text: "AUS (AUS) Sizing" },
    ]

    if (window.countryCode === 'GB' || window.location.hostname.includes('eur')) {
      currentCountrySizeData.push({ title: "uk_size", text: "United Kingdom (UK) Sizing" })
    } else if (window.countryCode === 'BR' || window.location.hostname.includes('br')) {
	  currentCountrySizeData.push({ title: "br_size", text: "Brazil (BR) Sizing" })
	} else if (window.countryCode === 'JP' || window.location.hostname.includes('jp')) {
	  currentCountrySizeData.push({ title: "jp_size", text: "JAPAN (JP) Sizing" })
	} else {
      currentCountrySizeData.push({ title: "eur_size", text: "Euro (EU) Sizing" })
    }

		this.state = {
			dropdownOpen: false,
			currentSize: undefined,
			lastFewMessageMobile : false,
			lastOneMessageMobile : false,
			currentCountrySizeActive: null,
			variants : null,
			multiCountrySize : null,
			showModal : false,
			currentSizeForotherCountry : null,
			currentCountrySizeData: currentCountrySizeData
		};
	}

	componentDidMount() {
		let variants = this.getVariants();
		this.variants = variants;
		let currentCountrySizeActive = localStorage.getItem("currentSizeCountry");

		if (!currentCountrySizeActive) {
			if (window.countryCode === 'GB' || window.location.hostname.includes('eur')) {
				currentCountrySizeActive = 'uk_size';
			} else if (window.countryCode === 'BR' || window.location.hostname.includes('br')) {
				currentCountrySizeActive = 'br_size';
			} else if (window.countryCode === 'JP' || window.location.hostname.includes('jp')) {
				currentCountrySizeActive = 'jp_size';
			} else if (window.continentCode === 'EU') {
				currentCountrySizeActive = 'eur_size';
			} else {
				currentCountrySizeActive = "us_size";
			}
		}

		if (localStorage.getItem("currentSizeCountry") && this.state.currentCountrySizeData?.size > 1) {
			let currentSizeCountry = localStorage.getItem("currentSizeCountry");
			currentCountrySizeActive = currentSizeCountry;
		}

		this.setState({
			currentCountrySizeActive: currentCountrySizeActive,
			variants: variants
		}, () => {
			this.changeCountrySize(currentCountrySizeActive);
			this.fireEventLoad(this.props.product)
			this.handleSizeGuideClick()

			// console.log(this.props, '211112')
			// console.log(this.props.product.variants)
			const initialFilteredAllNotAvaliable = this.props.product.node.variants.edges.filter(each => !each.node.availableForSale)
			const initialFilteredNotAvaliable = this.props.product.node.variants.edges.filter(each => each.node.availableForSale)
			document.addEventListener("keydown", this.keyDown, false);
			// console.log('errrrr', initialFilteredNotAvaliable)
			if (initialFilteredNotAvaliable.length > 0) {
				this.setState({
					currentSize: initialFilteredNotAvaliable[0].node.selectedOptions[2].value
				},() => {
					this.props.CurrentVariant(initialFilteredNotAvaliable[0], "node")
				});
			} else {
				//if all product's size's are not availableForSale
				this.setState({
					currentSize: this.props.sizeOption.values[0]
				},() => {
					this.props.CurrentVariant(initialFilteredAllNotAvaliable[0], "node")
				});
			}

			$(document).trigger('sizeselector:ready', [this.state.variants, this.state.currentCountrySizeActive])

			if (this.props.currentSize) {
				const currentVariant = this.variants.filter(variant => variant.size == this.props.currentSize);
				this.sizeSelected(currentVariant[0])
				this.showMessage(currentVariant[0])

				return
			}

			if(localStorage.getItem('currentSize')){
        let current_Size = localStorage.getItem("currentSize");
        let current_variant = this.variants.find(
          (variant) => variant.size == current_Size
        );

        if (current_variant) {
          this.sizeSelected(current_variant, isFirstSizeSelected);
          isFirstSizeSelected--
          this.showMessage(current_variant);
        }
			}
		})

		let modalIsOpen = false;
		function handleResize() {
			if(window.innerWidth > 1024) {
				return 'desktop'
			} else {
				return 'mobile'
			}
		}

		$('.js-modalSizeAction').on("click", function(e) {
			e.stopPropagation();
			if (!modalIsOpen) {
				if(handleResize() === 'desktop') {
					$('.size-box-overlay').addClass('white-overlay');
					$('.size-box-mobile-overlay').removeClass('white-overlay');
				} else {
					$('.size-box-mobile-overlay').addClass('white-overlay');
					$('.size-box-overlay').removeClass('white-overlay');
				}

				$('.icon-dropdown-green').removeClass('icon-down');
				$('.icon-dropdown-green').addClass('icon-up');
				$('.c-sizePickerModal__overlay').addClass('active');
				modalIsOpen = true
			} else {
				$('.size-box-overlay').removeClass('white-overlay');
				$('.size-box-mobile-overlay').removeClass('white-overlay');
				$('.icon-dropdown-green').removeClass('icon-up');
				$('.icon-dropdown-green').addClass('icon-down');
				$('.c-sizePickerModal__overlay').removeClass('active');
				modalIsOpen = false
			}
		})

		$('.c-sizePickerModal__countryBox').on("click", function(e) {
			e.stopPropagation();
		})

		$('body').on("click", function() {
			modalIsOpen = false;
			$('.c-sizePickerModal__overlay').removeClass("active");
			$('.icon-dropdown-green').removeClass('icon-up');
			$('.icon-dropdown-green').addClass('icon-down');
			$('.size-box-overlay').removeClass('white-overlay');
			$('.size-box-mobile-overlay').removeClass('white-overlay');
		})

		$('.size-box-overlay').on('click', function() {
			modalIsOpen = false;
			$('.c-sizePickerModal__overlay').removeClass("active");
			$('.icon-dropdown-green').removeClass('icon-up');
			$('.icon-dropdown-green').addClass('icon-down');
			$('.size-box-overlay').removeClass('white-overlay');
			$('.size-box-mobile-overlay').removeClass('white-overlay');
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.currentSize !== prevState.currentSize) {
			const select = this.selectRef.current;
			select.dispatchEvent(new Event('change'));
			localStorage.setItem('currentSize',this.state.currentSize );
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyDown, false);
	}

	keyDown = (event) => {
		if (event.keyCode === 27) {
			// Escape button pressed
			this.closeDropdown();
		}
	};

	isMen() {
		return this.props.isMen ?? !window.location.pathname.includes('women')
	}
	getFakeMenVariants = () => {
		const fakeMenSizes = [
			"5",
			"5.5",
			"6",
			"6.5",
			"7",
			"7.5"
		];

		let variants = this.props.oppGenderProduct;

		// Get only needed fields and map Women's sizes to Men's sizes
    variants = variants.variants.edges.map((variant) => {
      if(variant.node.id.indexOf("t/") >= 0){
        variant.node.id = formatId(variant.node.id);
      }

      return {
        id: variant.node.id,
        size: (
          parseFloat(variant.node.selectedOptions[2].value) - 1.5
        ).toString(),
        color: variant.node.selectedOptions[1].value,
        inventoryQuantity: variant.node.quantityAvailable,
        inventoryPolicy: variant.node.currentlyNotInStock,
        available: variant.node.availableForSale,
        productID: variants.id,
        sku: variant.node.sku,
        PRE_ORDER_DATE: variant.node.PRE_ORDER_DATE?.value,
        BACKORDER_DATE: variant.node.BACKORDER_DATE?.value,
        PROMISE_DATE: variant.node.PROMISE_DATE?.value,
        price: variant.node.price
      }
    });

		return variants.filter(variant => fakeMenSizes.includes(variant.size));
	};

	getFakeWomenVariants = () => {
		const fakeWomenSizes = [
			"9.5",
			"10",
			"10.5",
			"11",
			"11.5",
			"12",
			"12.5",
			"13"
		];

		let variants = this.props.oppGenderProduct;

		// Get only needed fields and map Men's sizes to Women's sizes
    variants = variants.variants.edges.map((variant) => {
      if(variant.node.id.indexOf("t/") >= 0){
        variant.node.id = formatId(variant.node.id);
      }

      return {
        id: variant.node.id,
        size: (
          parseFloat(variant.node.selectedOptions[2].value) + 1.5
        ).toString(),
        color: variant.node.selectedOptions[1].value,
        inventoryQuantity: variant.node.quantityAvailable,
        inventoryPolicy: variant.node.currentlyNotInStock,
        available: variant.node.availableForSale,
        title: variant.node.title,
        price: variant.node.price.amount,
        productID: variants.id,
  			sku: variant.node.sku,
        PRE_ORDER_DATE: variant.node.PRE_ORDER_DATE?.value,
        BACKORDER_DATE: variant.node.BACKORDER_DATE?.value,
        PROMISE_DATE: variant.node.PROMISE_DATE?.value
      }
    });

		return variants.filter(variant => fakeWomenSizes.includes(variant.size));
	};

	actualVariants = () => {
		let actualVariants = this.props.product.node.variants.edges;
    return actualVariants.map((variant) => {
      if(variant.node.id.indexOf("t/") >= 0){
        variant.node.id = formatId(variant.node.id);
      }

      return {
        id: variant.node.id,
        size: variant.node.selectedOptions[2].value,
        color: variant.node.selectedOptions[1].value,
        inventoryQuantity: variant.node.quantityAvailable,
        inventoryPolicy: variant.node.currentlyNotInStock,
        available: variant.node.availableForSale,
        title: variant.node.title,
        price: variant.node.price.amount,
        productID: this.props.product.node.id,
        sku: variant.node.sku,
        PRE_ORDER_DATE: variant.node.PRE_ORDER_DATE?.value,
        BACKORDER_DATE: variant.node.BACKORDER_DATE?.value,
        PROMISE_DATE: variant.node.PROMISE_DATE?.value
      }
    });
	};

	getVariants = () => {
		if (this.props.product.node.handle.includes('-men') && !this.props.oppGenderProduct.error) {
			return [
				...this.getFakeMenVariants(),
				...this.actualVariants(),
			];
		} if (this.props.product.node.handle.includes('-women') && !this.props.oppGenderProduct.error) {
			return [
				...this.actualVariants(),
				...this.getFakeWomenVariants(),
			];
		} else {
			return [
				...this.actualVariants(),
			];
		}
	};

	openDropdown = () => {
		this.setState({
			dropdownOpen: true
		});
	};

	closeDropdown = () => {
		this.setState({
			dropdownOpen: false
		});
	};

	toggleDropdown = () => {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	};

	dropdownClicked = () => {
		this.toggleDropdown();
	};


	fireEventLoad = (product) => {
		try {
      const sizes = this.getSizes(this.variants)
	    const availableSizes = this.getSizes(this.variants, true)

      if (window.SSPSTATUS) {
        window.SSPSTATUS.selectedColor = this.variants[0].color
        window.ssp_set('product', {
          productId: product.node.id,
          color: this.variants[0].color,
          price: parseInt(product.node.priceRange.maxVariantPrice.amount),
          sizes: sizes,
          availableSizes: availableSizes,
          sizeConversion: this.getSizeConversion()
        });
      }
    } catch (error) {
      console.log(error)
    }
	};

  fireEventVariantUpdate = (variant, product) => {
    /* GTM: variantUpdate : [Start]*/
    if (window.variantUpdate instanceof Function) {
      const eventVariantId = variant.id
      const eventProductId = product.node.id
      const eventSku = variant.sku

      if ((eventVariantId) && (eventProductId) && (eventSku)) {
        window.variantUpdate(eventVariantId, eventProductId, eventSku)
      }
    }
    /* GTM: variantUpdate : [End]*/

    /* Fit Predictor: Set Product : [Start]*/
    const sizes = this.getSizes(this.variants)
    const availableSizes = this.getSizes(this.variants, true)
    const color = variant.color

    if (window.SSPSTATUS) {
      window.SSPSTATUS.productId = product.node.id

      if (window.SSPSTATUS.selectedColor != color) {
        /* Changed: Color */
        window.SSPSTATUS.selectedColor = color
        window.ssp_set('product', {
          productId: product.node.id,
          color: window.SSPSTATUS.selectedColor,
          sizes: sizes,
          availableSizes: availableSizes,
          sizeConversion: this.getSizeConversion()
        });
      } else if (window.SSPSTATUS.variantId != variant.id) {
        /* Changed: Size */
        window.SSPSTATUS.variantId = variant.id
        var variantSize = variant.size;
        if (variant.multiCountrySize) {
          if (this.isMen()) {
            variantSize = variant.multiCountrySize.sizeMen;
          } else {
            variantSize = variant.multiCountrySize.sizeWomen;
          }
        }
        variantSize += ''
        window.SSPSTATUS.selectedSize = variantSize
        window.ssp_set('product', {
          productId: product.node.id,
          size: variantSize,
          variantId: window.SSPSTATUS.variantId,
          sizeConversion: this.getSizeConversion()
        });
      }
    }
    /* Fit Predictor: Set Product : [End]*/
  };

	fireEventCountryUpdate = (size) => {
		/* Fit Predictor: Set Product : [Start]*/
		if (window.SSPSTATUS) {
			if ((window.SSPSTATUS.productId) && (window.SSPSTATUS.variantId)) {
				window.ssp_set('product', {
					productId: window.SSPSTATUS.productId,
					size: size,
					variantId: window.SSPSTATUS.variantId,
					sizeConversion: this.getSizeConversion()
				});

				window.ssp_refresh();
			}
		}
		/* Fit Predictor: Set Product : [End]*/
	}

	getSizeConversion = () => {
		if ((window.SSPSTATUS) && (window.SSPSTATUS.market)) {
			const genderLabel = this.isMen() ? 'M' : 'F'

			return `${this.getSizeSystem()}-${genderLabel}`
		} else {
			return ''
		}
	};

	getSizeSystem = () => {
	let currentCountrySizeActive = "us_size";
	if (localStorage.getItem("currentSizeCountry")) {
		let currentSizeCountry = localStorage.getItem("currentSizeCountry");
		currentCountrySizeActive = currentSizeCountry;
	}

	var sizeSystem = 'US';

	switch (currentCountrySizeActive) {
		case 'us_size':
			sizeSystem = 'US';
		break;
		case 'uk_size':
			sizeSystem = 'UK';
		break;
		case 'eur_size':
			sizeSystem = 'EUR';
		break;
		case 'br_size':
			sizeSystem = 'BR';
		break;
		case 'aus_size':
			sizeSystem = 'AUS';
		break;
		case 'jp_size':
			sizeSystem = 'JP';
		break;
	}

	return sizeSystem;
	};

	getSizes = (variants, availableFlag = false) => {
		var sizes = variants.map((variant) => {
			if (variant.available || !availableFlag) {
				if (variant.multiCountrySize) {
					if (this.isMen()) {
						return variant.multiCountrySize.sizeMen + '';
					} else {
						return variant.multiCountrySize.sizeWomen + '';
					}
				} else {
					return variant.size + ''
				}
			}

			return ''
		})

		if ((sizes) && (sizes.length > 0)) {
			sizes = sizes.filter((value, index, self) => {
				return ((value != '') && (self.indexOf(value) == index));
			})
		}

		return sizes
	};

	sizeSelected = (variant, isFirst=0) => {
		if (!isFirst == 0) {
			this.fireEventVariantUpdate(variant, this.props.product);
		}

		// adding this for showing selected size to parent comm.
		this.setState({
			currentSize: variant.size,
			currentSizeForotherCountry: variant.multiCountrySize ?
			this.isMen() ? variant.multiCountrySize.sizeMen : variant.multiCountrySize.sizeWomen : variant.size
		},()=>{
			this.props.CurrentVariant(variant,"notNode");
			window.sspRenderPredictor();
		});
		this.closeDropdown();
	};

	showMessage = (variant) => {
		const totalQuantity = getVariantInventoryQuantity(variant)

			if (totalQuantity <= 7 && totalQuantity >= 2) {
				this.setState({
					lastFewMessageMobile : true
				});
			} else {
				this.setState({
					lastFewMessageMobile : false
				})
			}

			if (totalQuantity == 1) {
				this.setState({
					lastOneMessageMobile : true
				})
			} else {
				this.setState({
					lastOneMessageMobile : false
				})
			}

	}
	getMessage = (variant) => {
		const totalQuantity = getVariantInventoryQuantity(variant)
		const quantityThreshold = 3;
		if (totalQuantity <= 0 && !variant.inventoryPolicy) {
			return 'Notify Me';
		} else if (totalQuantity <= quantityThreshold) {
			return `${totalQuantity} Left`;
		}
	};

	getCountrySize(name, size) {
		if (GLOBAL && GLOBAL['table_size_guide']) {
			const sizes = GLOBAL['table_size_guide']
			return sizes.find(item => item[name] === size)
		}
		return ''
	};

	changeCountrySize = (selectedCountry) => {
		let variants = this.state.variants;

		let usSizeName = this.isMen() ? 'us_size_men' : 'us_size_women'

		if (selectedCountry == "us_size") {
			//us
			variants.map((variant) => {
				delete variant.multiCountrySize;
			})
		} else {
			if (selectedCountry === "uk_size" || selectedCountry === 'eur_size' || selectedCountry === 'br_size' || selectedCountry === 'aus_size') {
				//uk
				variants.map((variant) => {
					let multiCountrySize = {
						sizeUs : '' ,
						sizeCountry : '',
						sizeMen : '' ,
						sizeWomen : ''
					}
					multiCountrySize.sizeUs = variant.size;
					multiCountrySize.sizeCountry = selectedCountry;
					if (selectedCountry === 'br_size') {
					multiCountrySize.sizeCountry = 'br_size'
					} else if (selectedCountry === 'aus_size') {
					multiCountrySize.sizeCountry = 'uk_size'
					} else if (selectedCountry === 'jp_size') {
					multiCountrySize.sizeCountry = 'jp_size'
					}
					const currentCountrySize = this.getCountrySize(usSizeName, variant.size);
					multiCountrySize.sizeMen = currentCountrySize ? currentCountrySize[multiCountrySize.sizeCountry] : variant.size;
					multiCountrySize.sizeWomen = multiCountrySize.sizeMen;
					variant.multiCountrySize = multiCountrySize;
				})
			}
		}

		this.setState({
			currentCountrySizeActive: selectedCountry,
			showModal: false,
			variants: variants
		}, () => {
			localStorage.setItem("currentSizeCountry" , selectedCountry);
			let current_Size = this.state.currentSize;
			// console.log('current_Size',this.state.variants);
			let current_variant = this.state.variants.filter((variant) => variant.size == current_Size);
			// console.log(current_variant);
			this.sizeSelected(current_variant[0], isFirstSizeSelected);
			isFirstSizeSelected--
			this.showMessage(current_variant[0]);

			let current_selected_size = current_Size;
			const current_variant_obj = current_variant[0];
			if (current_variant_obj['multiCountrySize']) {
				if (this.isMen()) {
					current_selected_size = current_variant_obj['multiCountrySize']['sizeMen'] + ''
				} else {
					current_selected_size = current_variant_obj['multiCountrySize']['sizeWomen'] + ''
				}
			}

			this.fireEventCountryUpdate(current_selected_size);
			$(document).trigger('sizeselector:ready', [this.state.variants, this.state.currentCountrySizeActive])
		})
	};

	openModal = () =>{
		this.setState({
			showModal : true
		})
	}

	handleSizeGuideClick = () => {
		var $html = $('html'),
				$allPopups = $('.popup'),
				$overlay = $('.overlay');

		// Size Guide Popup
		var $sizeGuideTrigger = $('.js-size-guide-trigger'),
				$sizeGuidePopup = $('.popup__size-guide');

		// Trigger popup
		$sizeGuideTrigger.on('click', function(e) {
			e.preventDefault();
			$html.addClass('popup-shown');
			$allPopups.removeClass('shown');
			const target = $(this).attr('data-popup-target')
			const gender = $(this).attr('data-popup-gender')
			if (target && $(`[data-popup="${target}"]`).length > 0) {
				$sizeGuidePopup.removeClass('shown');
				$(`[data-popup="${target}"]`).addClass('shown');
				if (gender === 'men') {
					$(`[data-popup="${target}"]`).addClass('is-men')
				} else {
					$(`[data-popup="${target}"]`).removeClass('is-men')
				}
			} else {
				$sizeGuidePopup.addClass('shown');
			}
			$overlay.addClass('shown');
			slate.popUpShown = true;
		});

		// Close popups on button click
		$allPopups.on('click', 'a.close', function(e) {
			e.preventDefault();
			$html.removeClass('popup-shown');
			$allPopups.removeClass('shown');
			$overlay.removeClass('shown');
			slate.popUpShown = false;
		});
	}

  getCountrySizeLabel() {
    let country = 'US'

    switch (this.state.currentCountrySizeActive) {
      case 'uk_size':
        country = 'UK'
        break
      case 'eur_size':
        country = 'EUR'
        break
      case 'br_size':
        country = 'BR'
        break
      case 'aus_size':
        country = 'AUS'
        break
    }

    const size = this.state.currentSizeForotherCountry ? this.state.currentSizeForotherCountry : this.state.currentSize

    if (this.isMen()) {
      return translate(languages.country_size_men, { country, size })
    } else {
      return translate(languages.country_size_women, { country, size })
    }
  }
  	filterBrSizes(sizes) {
		if (window.Shopify?.shop == 'br-cariuma.myshopify.com') {
			return sizes && sizes.filter(s => !s.multiCountrySize?.sizeMen.includes('.'));
		}
		return this.variants;
	}
	render() {
		this.variants = this.filterBrSizes(this.variants);
		return (
			<Fragment>
				<div class="c-pdp__sizeHead">
					{ window.location.hostname.includes('br') || window.location.hostname.includes('eur') || window.countryCode === 'GB' && window.continentCode === 'EU' ?
					<div class="c-pdp__label--header c-pdp__label--has-size-picker">
						<div class="c-pdp__label c-pdp__label--header">
              {languages.size_label}
							<div class="c-pdp__textUpperContain--gbp">
								<strong class="c-pdp__textUpper js-modalSizeAction" onClick={this.openModal}>
								{this.state.productData !== null
                ? this.getCountrySizeLabel()
								: " Select Size "}
								<span class="c-pdp__sizeText c-pdp__textUpper--gbp"></span>
								<span class="c-pdp__arrow icon-dropdown-green icon-down"></span>
								</strong>
							</div>
							{this.state.currentCountrySizeActive !== null  ?
								<SizePickerModal
									currentCountrySizeData={this.state.currentCountrySizeData}
									currentCountrySizeActive={this.state.currentCountrySizeActive}
									changeCountrySize={this.changeCountrySize}
								/>
							: "" }
						</div>
					</div>
					:
					<span class="c-pdp__label c-pdp__label--header">
            {languages.size_label}
						<span class="c-pdp__textUpper">
							{this.state.productData !== null
                ? this.getCountrySizeLabel()
								: " Select Size "}
							<span class="c-pdp__sizeText"></span>
						</span>
					</span>
					}
					<div class="c-pdp__urgencyMessageContainer hidden-xs">
						{
						   this.state.lastFewMessageMobile ? <span className="c-pdp__lastFewMessage">{languages.stock_last_few_message}</span> : null
						}
					</div>

					<div class="c-pdp__label--size-wrapper">
						<div class="fp-root pdp-alt-hide-mobile" data-product-id={this.props.product.node.id}></div>
						<span class="c-pdp__label c-pdp__label--size">
							<a href={getUrl('/pages/size-fit-guide')} class="js-size-guide-trigger c-pdp__sizeGuide" data-popup-target={this.props.isAdult ? 'adult' : ''} data-popup-gender={this.isMen() ? 'men' : 'women'}>
                {languages.size_fit_guide}
							</a>
							<a href={getUrl('/pages/size-fit-guide')} class="c-cleanup-ab js-size-guide-trigger c-pdp__sizeGuide">
                {languages.size_guide}
							</a>
						</span>
					</div>
				</div>

				<div class="c-pdp__urgencyMessageContainer hidden-xs">
					{
					   this.state.lastFewMessageMobile ? <span className="c-pdp__lastFewMessage">{languages.stock_last_few_message}</span> : null
					}
				</div>

				<select id={'SingleOptionSelector-2'}
					data-single-option-selector
					data-index="option3"
					ref={this.selectRef}
					value={this.state.currentSize}
					hidden={true}
				>
					{this.variants && this.variants.map((variant,index) => (
						<option key={variant.id + index} value={variant.size}>{parseFloat(variant.size)}</option>
					))}
				</select>
				<div className={'dropdown'} tabIndex="0" style={{ outline: 'none' }} onBlur={this.closeDropdown}>
					<div className={'box'} onClick={this.dropdownClicked}>{this.state.currentSize}</div>
					<div className={'menu'} hidden={!this.state.dropdownOpen}>
						{this.variants && this.variants.map((variant, index) => (
							<div
							key={variant.id+index}
							 className={`${isRoundedCorner(this.variants.length, index + 1)} variant ${variant.size === this.state.currentSize && 'selected'} ${this.getMessage(variant) === 'Notify Me' ? 'soldout' : ''} `}  onClick={() => { this.sizeSelected(variant); this.showMessage(variant);}}>
								{this.isMen() ?
									<span className={"size"}>{
										variant.multiCountrySize ? variant.multiCountrySize.sizeMen : parseFloat(variant.size)
									} </span>
									: <span className={"size"}>{
										variant.multiCountrySize ? variant.multiCountrySize.sizeWomen : parseFloat(variant.size)
									} </span>
								}
								<span className={'message'}>{this.getMessage(variant)}</span>
							</div>
						))}
					</div>
				</div>
        <div class="fp-root pdp-alt-hide-desktop" data-product-id={this.props.product.node.id}></div>

			</Fragment>
		);
	}
}


export default SizeDropdown;
