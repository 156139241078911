/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact';
import axios from 'axios';
import { graphQLRequest } from '../collection/request';
import { Router, route } from 'preact-router';
import SizeAndCart from './SizeAndCart';
import ImageSlider from './ImageSlider';
import Cookies from "js-cookie";
import UniSexTabs from './UniSexTabs';
import fetch from "cross-fetch";
import { formatId, getCountryByCurrency, getPaymentsEnabled } from '../collection/utils';
import { getPrice, getProductsData, getStyleCollection, getSwatchData, getTaxAndDutiesMessage, productHasModel, isModelHidden } from './utils';
import InstallmentPayments from './InstallmentPayments';
import { formatMoney, taxInclusiveCurrencies, getPriceAmount } from '../../../utils/money'
import ModelPopup from './ModelPopup';
import getUrl from '@/scripts/utils/get-url'
import getLanguageCode from '@/scripts/utils/get-language-code'

const styleInfoCache = {};
const languages = window.pdpLanguages ?? {}

var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('gender');
class PdpUnisex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colors: null,
			isloading : true,
			products: null,
			productData: null,
			AllProductsMappedData: null,
			currentColor: null,
			allOppositeGenderData: null,
			menActive: false,
			product_category : null,
			product_category_link : null,
			featureImage1Src : '',
			featureImage3Src : '',
            sizeViewedImg : null,
			currentGender : "men",
			slideImages : null,
      styleInfo: {},
			isModelPopupOpen: false

		}
		this.dummyImages = [];
		this.dummyThumbnailImages = [0, 1, 2, 3, 4, 5];
		this.getData();
	}

	productQueryParams = () => {
		return `
			id
			onlineStoreUrl
			tags
			productType
      descriptionHtml
			alternativeTitle: metafield(namespace: "c_f", key: "name") {
				value
			}
			reviewWudget: metafield(namespace: "judgeme", key: "widget") {
				value
			}
			preorderMessage: metafield(namespace: "info", key: "preorder_message") {
				value
			}
			videoLinks: metafield(namespace: "c_f", key: "Video-ab") {
				value
				id
			}
			modelProjectId: metafield(namespace: "3d_model", key: "project_id") {
				value
			}
			restrictedCountries: metafield(namespace: "accentuate", key: "restricted_countries") {
				value
			}
      style: metafield(namespace: "info", key: "style") {
        value
      }
      forcePageReload: metafield(namespace: "info", key: "force_page_reload") {
        value
      }
      treePlant: metafield(namespace: "info", key: "tree_plant") {
        value
      }
			options {
				name
				values
			}
			review: metafield(namespace: "judgeme", key: "badge") {
				value
			}
			priceRange {
				minVariantPrice {
					amount
					currencyCode
				}
				maxVariantPrice {
					amount
					currencyCode
				}
			}
			handle
      title
      materialInformation: metafield(namespace: "info", key: "material_information") {
        value
      }
			images(first: 20) {
				edges {
					node {
						thumbnail: transformedSrc(maxWidth :75 ,maxHeight : 75 , scale: 3, crop : CENTER )
						altText
						originalSrc
					}
				}
			}
			variants(first: 15) {
				edges {
					node {
						id
						title
						price {
							amount
							currencyCode
						}
						availableForSale
						quantityAvailable
						currentlyNotInStock
						selectedOptions{
							name
							value
						}
            PRE_ORDER_DATE: metafield(namespace: "PRE_ORDER_DATE", key: "ORDER_DATE") {
              value
            }
            BACKORDER_DATE: metafield(namespace: "BACKORDER_DATE", key: "ORDER_DATE") {
              value
            }
						PROMISE_DATE: metafield(namespace: "HC_PREORDER", key: "PROMISE_DATE") {
							value
						}
          }
				}
			}
			seo {
				title
			}
		`
	}

	useDataDefault = (handle) => {
		const productDataQuery = /* GraphQL */ `
		query productDefaultData($handle: String!) @inContext(country: ${getCountryByCurrency(window.Shopify.currency.active)}, language: ${getLanguageCode()}) {
			productByHandle(handle: $handle) {
				${this.productQueryParams()}
			}
		}`;
		graphQLRequest(productDataQuery , { handle }, `${getUrl('/collections/')}${handle}?view=data`)
			.then((data) => {
				const products = {
					data: {
						collectionByHandle: {
							products: {
								edges: [{
									node: {
										...data.data.productByHandle
									}
								}]
							}
						}
					}
				}
				this.setState({
					products
				}, () => { this.mapProductData(); });
			})
			.catch((e) => {
				console.error(e);
			});
	}

	useDataBasic = (handle, nonCachedAPI) => {
		const collectionDataQuery = /* GraphQL */ `
		query collectionBasicData($handle: String!) @inContext(country: ${getCountryByCurrency(Cookies.get("cart_currency"))}, language: ${getLanguageCode()}) {
			collectionByHandle(handle: $handle) {
				id
				products(first: 250) {
					edges {
						node {
							${this.productQueryParams()}
						}
					}
				}
			}
		}`;
		graphQLRequest(collectionDataQuery , {handle}, `${getUrl('/collections/')}${handle}?view=data`, nonCachedAPI)
			.then((data) => {
				if(data.errors && data.errors.length>0){
                    console.log('Erro from shopify side==> DEV ANATTA');
				}else{
					this.setState({ products: data }, () => {this.mapProductData(); });
				}

			})
			.catch((e) => {
				console.error(e);
			});

	};
	getData = (nonCachedAPI = false) =>{
		// console.log('cartjs', CartJS);
		const Pdppage = document.querySelector('#js-pdpUnisexPageContent');
    const familyCollection = Pdppage.dataset.familyCollection;
		const handle = familyCollection;

		if (handle) {
			this.useDataBasic(handle, nonCachedAPI);
		} else {
			this.useDataDefault(window.location.pathname.split('/').pop())
		}
	}

    componentWillMount(){
      if (myParam == null && !Shopify.designMode) {
        window.location.href = `${window.location.pathname}?gender=women`;
      }
    }
    componentWillUpdate(newProps,newState){

    }
	componentDidUpdate(_, prevState) {
		// console.log('updateeee', this);
	   $(".c-collectionGrid__itemLink").click(function(){
			// window.location.replace=$(this).attr("href");
			window.location.replace($(this).attr("href"));
			return false;
		 });
		 $(".js-clickable_react").click(function(){
			// window.location.replace=$(this).attr("href");
			window.location.replace($(this).attr("href"));
			return false;
		 });
	   $(".footer-gift-card").click(function(){
		// window.location.replace=$(this).attr("href");
		window.location.replace($(this).attr("href"));
		return false;
		   });

    if (this.state.menActive !== prevState.menActive) {
      this.setProductCategory()
    }
	}
	setProductCategory(currentProduct = null){
    return new Promise(async (resolve) => {
      currentProduct = currentProduct ?? this.state.AllProductsMappedData.find(product => {
        if (window.location.pathname.includes(product.product.node.handle)) {
          return true;
        }
      });

      if (!currentProduct) {
        resolve();
        return;
      }

      const styleCollection = getStyleCollection(currentProduct.product.node.style?.value, this.state.menActive, true);

      if (!styleCollection) {
        this.setState({
          styleInfo: null,
          product_category: '',
          product_category_link: ''
        }, resolve)

        return;
      }

      const styleInfo = await this.getStyleInfo(styleCollection);
      const product_category = styleInfo.styleTitle;
      const product_category_full = styleInfo.styleTitle + (styleInfo.styleAlias ? ` (${styleInfo.styleAlias})` : '');
      const product_category_link = styleInfo.url;

      this.setState({
        styleInfo,
        product_category,
        product_category_full,
        product_category_link
      }, resolve)
    });
	}

  async getStyleInfo(collectionHandle) {
    return new Promise(resolve => {
      if (collectionHandle in styleInfoCache) {
        resolve(styleInfoCache[collectionHandle]);
        return;
      }

      axios.get(getUrl('/collections/') + collectionHandle + '?view=style-info')
        .then(response => {
          styleInfoCache[collectionHandle] = response.data
          resolve(styleInfoCache[collectionHandle]);
        })
        .catch(() => {
          resolve({});
        })
    })
  }

	mapProductData() {
		var me = this;
		var AllProductData = me.state.products
		// console.log('real data', me.state.products);
		var AllProductsMappedData = [];

		AllProductData.data.collectionByHandle.products.edges.map((product) => {
			if (!product.node.priceWithoutTax) {
				const isGiftCard = product.node.handle.includes('gift-card');
				const price = getPrice(product.node.priceRange, isGiftCard);

				product.node.priceRange = price.currencyBasePrice;
				product.node.priceWithoutTax = price.priceWithoutTax;
			}
            	// console.log('cuerrent prod', product.product.node.images.edges);
				let videoLink = product.node.images.edges.filter(image => image.node.originalSrc.includes('video.slideshow'));
				if(window.innerWidth>767){
					if(videoLink && !videoLink.includes('desktop')){
						if(product.node.videoLinks !=null && product.node.videoLinks.value){
						let desktopVideoLink=[{
							node: {
								altText : product.node.videoLinks.value,
								originalSrc : product.node.videoLinks.value ,
								thumbnail : product.node.videoLinks.value
							}
						}]
						videoLink = desktopVideoLink;
						}
					}
                }
                let slideImages , thumbnail ,featureImage1Src, sizeViewedImg ,featureImage3Src;
                slideImages =[];
                for( let image of product.node.images.edges){
                    if(image.node.originalSrc.includes('feature-1')){
                         featureImage1Src = [image]
                    }else{
                        if(image.node.originalSrc.includes('side-view')){
                            sizeViewedImg = [image]
                        }else{
                            if(image.node.originalSrc.includes('feature-3')){
                                featureImage3Src = [image]
                            }else{
                                if(image.node.originalSrc.includes('slideshow')){
                                     slideImages.push(image);
                                }else{
                                    if(image.node.altText!=null && image.node.altText.includes('pdpThumbnail')){
                                     thumbnail=image.node.originalSrc;
                                   }
                                }

                            }
                        }
                    }
                }

            console.log(product);
            let color = {
                color_title: product.node.options[1].values[0],
                thumbnail_image: thumbnail,
                url : getUrl(`/products/${product.node.handle}`)
            }
            product.node.id = formatId(product.node.id);
			const mapProduct = { product, color : color , featureImage3Src , featureImage1Src ,slideImages , sizeViewedImg ,videoLink}
			AllProductsMappedData.push(mapProduct);
		})
		this.setState({
			AllProductsMappedData: AllProductsMappedData,
		}, () => { this.currentProduct() })
	}
	//this is for changing colours
	changecolor = (Currentproduct) => {
		if (Currentproduct?.product.node.forcePageReload?.value === 'true' || this.state.productData?.product.node.forcePageReload?.value === 'true') {
      const searchParams = new URLSearchParams(window.location.search);
			window.location = Currentproduct.product.node.onlineStoreUrl + (searchParams.size ? `?${searchParams.toString()}` : '');
		} else {

			if (Currentproduct.product.node.seo?.title) {
				document.title = `CARIUMA: ${Currentproduct.product.node.seo.title}`;
			} else {
				document.title = `CARIUMA: ${Currentproduct.product.node.title}`;
			}
			this.setState({
				productData: Currentproduct,
				slideImages: {
					edges: Currentproduct.slideImages
				},
				currentColor: Currentproduct.color.color_title,
				featureImage1Src: Currentproduct.featureImage1Src,
				featureImage3Src: Currentproduct.featureImage3Src,
				sizeViewedImg: Currentproduct.sizeViewedImg,
				isModelPopupOpen: false
			}, () => {
				this.setProductCategory(Currentproduct)
					.then(() => {
						this.createMarkup();
						this.updateProductDetails();
						this.changeGender(this.state.menActive)
					});

				if (typeof window.getRecentPdp == 'function') {
					window.getRecentPdp()
				}
				if (typeof window.setRecentlyViewedPdp == 'function') {
					window.setRecentlyViewedPdp()
				}

				const event = new CustomEvent('productColorChange', {
					detail: {
						product: Currentproduct.product
					}
				})
				document.dispatchEvent(event)
			});
		}
	}
	//this function is just for first time load
	async currentProduct() {
		await this.setProductCategory()
		this.setState({
			isloading : false
		})

    if (window.location.hash === '#reviews') {
      document.getElementById('judgeme_product_reviews')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

		// console.log('route', window.location.pathname);
		this.state.AllProductsMappedData.map((product) => {
			if (window.location.pathname.includes(product.product.node.handle)) {
				product={ ...product, "videoLink": product.videoLink}
				this.setState({
          productData: product,
          slideImages: {
            edges: product.slideImages
          },
          currentColor: product.color.color_title,
          featureImage1Src: product.featureImage1Src,
          featureImage3Src: product.featureImage3Src,
          sizeViewedImg :product.sizeViewedImg,
          isModelPopupOpen: false
        }, ()=>{this.createMarkup()});
			}
		});

    }
	createMarkup() {
		document.querySelectorAll('.c-stickyBarPdp__title')[0].innerText=document.querySelectorAll('.c-pdp__productName')[0].innerText;
    window.requestAnimationFrame(() => {
      const stickyBarSubHeadlineEl = document.querySelector('.js-c-stickyBarPdp__subheadline')
      const productCatEl = document.querySelector('.c-pdp__productCat')
      if (stickyBarSubHeadlineEl) stickyBarSubHeadlineEl.innerText = productCatEl.innerText;
    })

		document.querySelectorAll('.review_badge_ctr').forEach(reviewCtr => {
      console.log(reviewCtr)
      reviewCtr.style.display = 'block';
      reviewCtr.addEventListener('click', () => {
        document.getElementById('judgeme_product_reviews')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      })
    })
    }
    changeGender = (isMen) =>{
		// this.state.productData.product.node.slideImages.edges
		let getSlideImage={
			edges : []
		};
		console.log(this.state.productData.slideImages, this.state.slideImages.edges);
		if(isMen){
			//remove women for other colours other than white
			this.state.productData.slideImages.forEach((each )=>{
				if(each.node.altText!=="slideshow1Women" && each.node.altText!=="shoulderFemale"){
					getSlideImage.edges.push(each);
				}
			});

		}else{
			//remove shoulder images for other colours othe than white
			this.state.productData.slideImages.forEach((each )=>{
			if(each.node.altText!=="shoulderMan" && each.node.altText!=="slideshow1Men"){
				getSlideImage.edges.push(each);
			}});

		}


        this.setState({
			menActive : isMen,
			slideImages : getSlideImage
        })
    }
	updateProductDetails = () => {
    let me = this;
		var readyStateCheckInterval = setInterval(function() {
			if (document.readyState === "complete") {
        clearInterval(readyStateCheckInterval);

				if($('.c-featuredCol__70')){
					if($('.c-featuredCol__70').first().find('source').length>0 && $('.c-featuredCol__70').first().find('source')?.attr("srcset")?.includes('feature-1')){
							let featureImage1 =$('.c-featuredCol__70').first().find('source');
							if(me.state.featureImage1Src &&  (me.state.featureImage1Src.length>0 && me.state.featureImage1Src[0].node)){
								featureImage1.attr("srcset", me.state.featureImage1Src[0].node.originalSrc);
								let imageTag=
								`<div class="ratio ratio-feature-1">
                  <picture>
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 1440px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 1024px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 900px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 767px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 500px)">
  									<img loading="lazy"
  										src="${me.state.featureImage1Src[0].node.originalSrc}"
  										alt="Hero image">
  								</picture>
                </div>`;
								$('.c-featuredCol__70').first().find('div.ratio-feature-1').remove();
								$('.c-featuredCol__70').first().prepend(imageTag);
							}
					}
				}

				if($('.c-featuredCol__50')){
					if($('.c-featuredCol__50').first().find('source').length>0 && $('.c-featuredCol__50').first().find('source')?.attr("srcset")?.includes('feature-3')){
						let featureImage3 =$('.c-featuredCol__50').first().find('source');
						if(me.state.featureImage3Src &&  (me.state.featureImage3Src.length>0 && me.state.featureImage3Src[0].node)){
								featureImage3.attr("srcset", me.state.featureImage3Src[0].node.originalSrc);
								let imageTag3=
								`<div class="ratio ratio-feature-block-2">
									<picture>
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 1440px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 1024px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 900px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 767px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 500px)">
										<img loading="lazy"
											src="${me.state.featureImage3Src[0].node.originalSrc}"
											alt="Hero image">
									</picture>
								</div>`;
								$('.c-featuredCol__50').first().find('.ratio-feature-block-2').remove();
								$('.c-featuredCol__50').first().prepend(imageTag3);
						}
					}
				}

				if($('.c-featuredCol__50')){
					if($('.c-featuredCol__50').find('.c-featured__description')){
            let descriptionSplit = me.state.productData.product.node.descriptionHtml.split('<p>[ split ]</p>');
						let description = descriptionSplit[0];
            let productSpecs = descriptionSplit[1];

						$('.c-featuredCol__50').find('.c-featured__description').html(description);

            if (productSpecs) {
              $('.c-featuredCol__50').find('.c-pdpContent__specCont').html(productSpecs);
            }
					}
				}
			}
		}, 10);

	}

	openModelPopup = () => {
		this.setState({
			isModelPopupOpen: true
		})
	}

	closeModelPopup = () => {
		this.setState({
			isModelPopupOpen: false
		})
	}

  onSizeAndCartUpdate = () => {
    if (this.firstLoad) {
      this.firstLoad = false

      const scrollToCenter = ($container, $activeEl) => {
        if ($container.length && $activeEl.length) {
          const scrollLeft = $container.scrollLeft() + $container.offset().left + $activeEl.offset().left
            - ($container.width() - $activeEl.width()) / 2 + parseInt($activeEl.css('paddingLeft'))

          $container.scrollLeft(scrollLeft)
        }
      }

      setTimeout(() => {
        const $swatches = $('.js-swatch')
        const $activeSwatch = $swatches.find('.is-active')
        scrollToCenter($swatches, $activeSwatch)

        const $sizes = $('.c-pdp__size .menu')
        const $activeSize = $sizes.find('.selected')
        scrollToCenter($sizes, $activeSize)
        window.sizeScrollLeft = $sizes.scrollLeft()

        $('.jdgm-prev-badge').on('click', () => {
          const $mobileReviews = $('.c-mobileReview:not(:hidden)')

          if ($mobileReviews.length) {
            $mobileReviews[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
        })
      }, 0)
    } else {
      const $sizes = $('.c-pdp__size .menu')

      if (!$sizes.hasClass('is-initialized')) {
        $sizes.addClass('is-initialized')
        $sizes.on('scroll', function() {
          window.sizeScrollLeft = $sizes.scrollLeft()
        })
      }

      if (window.sizeScrollLeft) {
        $sizes.scrollLeft(window.sizeScrollLeft)
      }
    }
  }


	refreshProductInfo = () => {
		return this.getData(true)
	}

	render() {
    const hasModel = productHasModel(this.state.productData)
    const modelHidden = isModelHidden(this.state.productData)
		const price = this.state.productData?.product.node.priceRange.maxVariantPrice.amount ?? ''
		const discountPrice = this.state.productData?.product.node.tags.includes('_gated_archive_sale') ? getDiscountedPrice(price) : price

		return (
			<Fragment>
				{this.state.isloading ?
				<div class="c-pdp" >
					<div class="c-liveContent">
						<div class="container">
							<div class="c-pdp__breadCrumb">
								<div class="shimmer" style="width: 100px; height: 18px; vertical-align: middle;"></div>
							</div>
						</div>
					</div>
					<div class="container container--mobile-no-pad">
						<div class="c-pdp__grid c-pdpGrid">
							<div class="c-pdp__gridImg c-pdpGrid__item c-pdpSlider">
								<div class="shimmer" style="width: 100%; padding-bottom: 64.7%"></div>
							</div>
							<div class="c-pdp__gridInfo c-pdpGrid__item">
								<div class="c-pdp__productHead">
									<div class="c-pdp__productHeadItem c-pdp__productHeadLeft">
										<h1 class="c-pdp__productName shimmer" style="width: 232px; height: 36px;"></h1>
									</div>
									<div class="c-pdp__productHeadItem c-pdp__productHeadRight">
										<h1 class="c-pdp__productCat shimmer" style="width : 100px; height: 21px;"></h1>
									</div>
								</div>
								<h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent">
									<span class="shimmer" style="width :100px ; height : 21px;">&nbsp;</span>
								</h4>
								<div class="shimmer" style="width: 100%; height: 20px; vertical-align: middle;"></div>
								<div class="c-pdp__gridBox">
									<div class="c-pdp__gender">
										<div class="c-pdp__genderWrap shimmer" style="height: 47px;">
											&nbsp;
										</div>
									</div>
									<div class="c-pdp__gridBoxWrap" >
										{/* {% if has_swatches %} */}
										<div className="c-swatches c-swatchesDesktop ">
											<span className="c-pdp__label c-pdp__labelDyanmically c-pdp__label--colorText shimmer">
											&nbsp;
											</span>

												<div className="c-swatch js-swatch c-swatch__imageThumbnail" style="margin: 0;">
													{this.dummyThumbnailImages.map((index) => {
														return <a style="width: calc(100% / 6) ; height: 77px" key={index} className='swatch-image-thumbnail shimmer'
														>
														</a>
													})}
												</div>
										</div>
											<div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
												<div class="size-quantity c-pdp__size">
													<div class="selector-wrapper js{% if option.values.size == 1 %} hide{% endif %}">
														<div class="c-pdp__sizeHead shimmer">
															<div class="c-pdp__label c-pdp__label--header" style="width: 100px;">&nbsp;</div>
														</div>
														<div className={'dropdown'} tabIndex="0" >
															<div className={'box'} ></div>
															<div className={'menu'} >
																{this.dummyThumbnailImages.map((variant, index) => (
																	<div className={`shimmer`} style="width: calc(calc(100% / 9) - 1px); height: 55px;"></div>
																))}
															</div>
														</div>
													</div>
													<label id="quantitySKUMsg" style="margin-top: 5px; color: red; text-align: center; font-weight: normal; display: none; text-transform:unset">
													</label>
													<input type="number" value="{{cart.item_count}}" style="display: none;" id="productPageCartQty" />
												</div>
												<div class="send">
													<input id="variantCart-{{cartItem.variant_id}}" value="{{cartItem.quantity}}" class="d-none" />
													<button
														type="submit"
														id="addCartBt"
														name="add"
														class="btn btn-full c-abBtnAtc pdp-addCartBtn-sticky "
														data-add-to-carts
														onClick={this.AddtoCart}>
														<span data-add-to-cart-text>
                              {languages.add_to_cart}
														</span>
													</button>
												</div>
												<div class="c-pdp__ecoMessageContain">
													<div class="c-pdp__ecoMessage shimmer" style="width: 100%; height: 23px;"></div>
												</div>
											</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				:
				<div class="c-pdp" data-section-id="{{ section.id }}" data-section-type="product" data-enable-history-state="true" itemscope itemtype="http://schema.org/Product">
					<div class="c-liveContent">
						<div class="container">
							<div class="c-pdp__breadCrumb c-pdp__breadCrumb--secondary">
							<a href={this.state.menActive !== null ? this.state.menActive ? getUrl('/collections/men') : getUrl('/collections/women') : ''} class="link-internal"><span class="c-pdp__breadCrumbText c-pdp__breadCrumbText--secondary">
                {this.state.menActive !== null ? this.state.menActive ? 'Men' : 'Women' : ""}</span></a>

                <span class="c-pdp__breadCrumbIcon c-pdp__breadCrumbIcon--secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 7 10">
                    <path stroke="#4A4A4A" stroke-width="2" d="m1 1 4 4-4 4"/>
                  </svg>
                </span>
								<a href={this.state.product_category_link!==null ? getUrl(this.state.product_category_link) : ""} class="link-internal">
                  <span class="c-pdp__breadCrumbText">
                    {this.state.product_category!==null ? this.state.product_category : ""}
                  </span>
                </a>
							</div>
						</div>
					</div>
					<div class="container container--mobile-no-pad">
          <div class="c-pdp__productHead pdp-alt-hide-desktop-flex">
              <div class="c-pdp__productHeadItem c-pdp__productHeadLeft">
                {this.state.productData !== null ?
                  <h2 class="c-pdp__productName">
                    {this.state.productData.product.node.alternativeTitle ? this.state.productData.product.node.alternativeTitle.value : '' }
                  </h2>
                  :
                  <h1 class="c-pdp__productName shimmer" style="width: 232px; height: 30px;"></h1>
                }

                {this.state.productData !== null ?
                  <h4 class="c-pdp__productCat">{this.state.product_category_full !== null ? this.state.product_category_full : ""}</h4>
                  :
                  <h4 class="c-pdp__productCat shimmer" style="width : 100px;"></h4>
                }
              </div>
              <div class="c-pdp__productHeadItem c-pdp__productHeadRight">
                {this.state.productData !== null ?
                  <h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent" data-price-wrapper>
                    {this.state.productData.product.node.priceRange.maxVariantPrice && (
                      <Fragment>
                        {taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode) ? (
                          <span class="mobile-text" data-product-price="">
                            <span class="c-taxInclusive">
                              {formatMoney(discountPrice, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              {discountPrice < price && <span class="original-price">{formatMoney(price, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}</span>}
                              <span class="product_vatText">
                                {getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              </span>
                            </span>
                            <span class="c-taxExclusive">
                              {formatMoney(this.state.productData.product.node.priceWithoutTax.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, false)}
                              <span class="product_vatText">
                                {` ${getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, true)}`}
                              </span>
                            </span>
                          </span> 
                        ) : (
                          <span class="mobile-text" data-product-price="">
                            {formatMoney(discountPrice, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                            {discountPrice < price && <span class="original-price">{formatMoney(price, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}</span>}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </h4>
                  :
                  <h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent shimmer" style="width :100px ; height : 21px;"></h4>
                }

                <div class='c-pdp__productReview jdgm-widget jdgm-preview-badge c-pdp__productReview--nonAB-test review_badge_ctr' style="display : block">
                  {this.state.productData !== null && !this.state.productData.product.node.review?.value.includes("No reviews") && !window.pdpHideReviews ?
                    <div id="review_badge" dangerouslySetInnerHTML={{ __html: this.state.productData.product.node.review?.value}} />
                    :
                    ''
                  }
                </div>
              </div>
            </div>
						<div class="c-pdp__grid c-pdpGrid">
							{this.state.productData !== null ?
								this.state.slideImages.edges.length > 1 ?
									<ImageSlider
										images={this.state.slideImages.edges}
										video={this.state.productData.videoLink.length!==0 ? this.state.productData.videoLink : null}
                    tags={this.state.productData.product.node.tags}
										hasModel={hasModel}
                    isModelHidden={modelHidden}
										onOpenModelButtonClick={this.openModelPopup}
                  />
									: " " :
									<div class="c-pdp__gridImg c-pdpGrid__item c-pdpSlider shimmer" >
									</div>
							}
							<div class="c-pdp__gridInfo c-pdpGrid__item">
								<div class="c-pdp__productHead hide-mobile">
									<div class="c-pdp__productHeadItem c-pdp__productHeadLeft">
									{this.state.productData !== null ?
										<h1 class="c-pdp__productName">
											{this.state.productData.product.node.alternativeTitle ? this.state.productData.product.node.alternativeTitle.value : '' }
										</h1>
										:
										<h1 class="c-pdp__productName shimmer" style="width: 232px;
										height: 30px;">
										</h1>
										}
									</div>
									<div class="c-pdp__productHeadItem c-pdp__productHeadRight">
										{this.state.productData !== null ?
											<h4 class="c-pdp__productCat">{this.state.product_category_full !== null ? this.state.product_category_full : ""}</h4>
											: <h4 class="c-pdp__productCat shimmer" style="width : 100px;"></h4>}
										<div class='c-pdp__productReview jdgm-widget jdgm-preview-badge c-pdp__productReview--nonAB-test review_badge_ctr' style="display : block"
										>
											{this.state.productData !== null && !this.state.productData.product.node.review?.value.includes("No reviews") && !window.pdpHideReviews ?
											<div id="review_badge" dangerouslySetInnerHTML={{
												__html: this.state.productData.product.node.review?.value}} />
												:
												'' }
										</div>
									</div>
								</div>
								{this.state.productData !== null ?
									<h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent hide-mobile" data-price-wrapper>
                    {this.state.productData.product.node.priceRange.maxVariantPrice && (
                      <Fragment>
                        {taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode) ? (
                          <span class="mobile-text" data-product-price="">
                            <span class="c-taxInclusive">
                              {formatMoney(discountPrice, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              {discountPrice < price && <span class="original-price">{formatMoney(price, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}</span>}
                              <span class="product_vatText">
                                {getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              </span>
                            </span>
                            <span class="c-taxExclusive">
                              {formatMoney(this.state.productData.product.node.priceWithoutTax.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, false)}
                              <span class="product_vatText">
                                {` ${getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, true)}`}
                              </span>
                            </span>
                          </span>
                        ) : (
                          <span class="mobile-text" data-product-price="">
                            {formatMoney(discountPrice, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                            {discountPrice < price && <span class="original-price">{formatMoney(price, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}</span>}
                          </span>
                        )}
                      </Fragment>
                    )}
									</h4>
									:
									<h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent shimmer" style="width :100px ; height : 21px;">

									</h4>
								}

                {(this.state.productData && getPaymentsEnabled(this.state.productData.product.node.priceRange.minVariantPrice.currencyCode).showPayments) && (
                <div class="pdp-alt-hide-mobile payments-wrapper">
                  <InstallmentPayments
                    currency={this.state.productData.product.node.priceRange.minVariantPrice.currencyCode}
                    amount={taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)
                      ? getPriceAmount(discountPrice, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, true, window.Shopify.country)
                      : discountPrice
                    }
                  />
                </div>
                )}

								<div class="c-pdp__gridBox">
									<Router>
										<Profile path={getUrl("/products/:user?v")} item={this.state.productData !== null ? this.state.productData : ''} />
									</Router>
									<div class="c-pdp__gender">
                                        {this.state.productData !== null ?
                                            <UniSexTabs
                                            changeGender = {this.changeGender}
                                            />
											: <div class="c-pdp__genderWrap" >
												<a class="c-abGender__btn  c-men is-active" ><span>MEN</span></a>
												<a class="c-abGender__btn c-women"><span>WOMEN</span></a>
											</div>
										}
									</div>
									<div class="c-pdp__gridBoxWrap" >
										<div className="c-swatches c-swatchesDesktop ">
											<span className="c-pdp__label c-pdp__labelDyanmically c-pdp__label--colorText">
                        {languages.color_label}
												<span class="c-pdp__colorText c-pdp__textUpper">{this.state.currentColor !== null ? this.state.currentColor : ''}</span>
											</span>
											{this.state.AllProductsMappedData !== null ?
											<div className="c-swatch js-swatch c-swatch__imageThumbnail lazyloaded">
												{this.state.AllProductsMappedData.map((product, index) => {
													return <a key={product.color.color_title + index} className={product.color.color_title === this.state.currentColor ? "is-active swatch-image-thumbnail  " : 'swatch-image-thumbnail '}
														href={`${product.color.url}?gender=${this.state.menActive ? 'men' : 'women' }`} onClick={() => { this.changecolor(product) }}>
														<img src={product.color.thumbnail_image} />
													</a>
												})}
											</div>
											:	<div className="c-swatch js-swatch c-swatch__imageThumbnail  ">
													{this.dummyThumbnailImages.map((index) => {
														return <a style="width : 70px ; height : 70px" key={index} className='swatch-image-thumbnail shimmer'
														>
														</a>
													})}
												</div>
												}
										</div>

										{this.state.productData !== null ?
											<SizeAndCart
												key={this.state.productData.product.node.handle+Math.random()*9080}
												product={this.state.productData.product}
												current_size={this.state.productData.current_size}
												oppGenderProduct={null}
												sizeOption={this.state.productData.product.node.options[2]}
												productCategory={this.state.product_category}
												sizeViewedImg = {this.state.sizeViewedImg}
												refreshProductInfo={this.refreshProductInfo}
                        currencyCode={this.state.productData.product.node.priceRange.minVariantPrice.currencyCode}
                        onUpdate={this.onSizeAndCartUpdate}
                        isAlt={true}
											/>
											:
											<div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
												<div class="size-quantity c-pdp__size">
													<div class="selector-wrapper js{% if option.values.size == 1 %} hide{% endif %}">
														<div class="c-pdp__sizeHead shimmer">
														</div>
														<div className={'dropdown'} tabIndex="0" >
															<div className={'box'} ></div>
															<div className={'menu'} >
																{this.dummyThumbnailImages.map((variant, index) => (
																	<div className={`variant shimmer`}>
																		<span className={'size shimmer'}></span>
																	</div>
																))}
															</div>
														</div>
													</div>
													<label id="quantitySKUMsg" style="margin-top: 5px; color: red; text-align: center; font-weight: normal; display: none; text-transform:unset">
													</label>
													<input type="number" value="{{cart.item_count}}" style="display: none;" id="productPageCartQty" />
													<div class="send">
														<input id="variantCart-{{cartItem.variant_id}}" value="{{cartItem.quantity}}" class="d-none" />
														<button
															type="submit"
															id="addCartBt"
															name="add"
															class="btn btn-full c-abBtnAtc pdp-addCartBtn-sticky "
															data-add-to-carts
															onClick={this.AddtoCart}>
															<span data-add-to-cart-text>
                                {languages.add_to_cart}
															</span>
														</button>
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
						<div class="c-ozyContent">
							{"include 'ozy-content' "}
						</div>

            {hasModel && (
              <ModelPopup
                isOpen={this.state.isModelPopupOpen}
                modelProjectId={this.state.productData?.product?.node?.modelProjectId?.value}
                onClose={this.closeModelPopup}
              />
            )}
					</div>
				}
			</Fragment>
				);
			}
		}


		/** handles /profile and /profile/:user */
const Profile = ({user, ...props }) => (
	<section class="profile">
	</section>
);


export default PdpUnisex;
