/**
 *
 * @prettier
 */

import { h } from 'preact';
/** @jsx h */
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { memo } from 'preact/compat';
import { i18n, svgUrl } from './utils';
import { trackProductCLick } from './gtm';
import { barData } from './data';
import { formatMoney } from '../../../utils/money'
import getUrl from '@/scripts/utils/get-url'

const skeletonElement = (
	<article class="c-collectionProductV2 c-collectionProductV2--skeleton">
		<div class="c-collectionProductV2__link">
			<div class="c-collectionProductV2__imageWrapper">
				<div class="c-collectionProductV2__imageSpacer">
					<div class="c-collectionProductV2__imageSkeleton" />
				</div>
			</div>

			<div class="c-collectionProductV2__bottom">
				<div class="c-collectionProductV2__titleWrapper">
					<div class="c-collectionProductV2__titleSkeleton">&nbsp;</div>
				</div>

				<div class="c-collectionProductV2__priceWrapper">
					<div class="c-collectionProductV2__priceSkeleton">&nbsp;</div>
				</div>
			</div>
		</div>
	</article>
);
const sizes=[5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10,10.5,11,11.5,12,12.5,13]

const Product = ({ skeleton, collectionHandle, product, headingLevel , bannerImgProduct ,imageIGProduct, hideLabel }) => {
	if (skeleton) {
		return skeletonElement;
	}
	const isMen = barData.menCollections.includes(collectionHandle);
	const isWomen = barData.womenCollections.includes(collectionHandle);
 
	const [category, setCategory] = useState('');
	useEffect(async () => {
		const info = await fetch(`/products/${product.handle}?view=info`)
			.then(response => response.json())
			.then(json => {
				return json
			})
			.catch(() => {})
		const category = info && info.categoryTitle ? info.categoryTitle : ''
		setCategory(category)
	})
 
	const toggelSize = (e) =>{
		e.stopPropagation();
		e.preventDefault();
		// console.log(e.currentTarget);
		e.currentTarget.style.display= "none"
		e.currentTarget.nextSibling.style.display= "block"
	}
	const toggelSizeOnHoverOut = (e) =>{
		e.stopPropagation();
		if(e.currentTarget.classList.contains('c-quickView__size')){
			e.currentTarget.style.display = "none";
			e.currentTarget.previousSibling.style.display= "block"
		}
	}
	//checking click event for parent div for applying event delegation
	const selectSize = (e) =>{
		let actualSize = e.target.innerText;
		e.stopPropagation();
		e.preventDefault();
		if(actualSize!=='' && actualSize.length<5){
			e.target.classList.add('size-active');
			// console.log(e.currentTarget);
			let size= actualSize;
			localStorage.setItem('currentSize', size);
			e.currentTarget.parentNode.parentNode.click();
		}
	}
	const { title, videoPLPUrl } = product;
	let image = product.image && product.image.url
	let imageHover =
		product.imageHover && product.imageHover.url

	if(bannerImgProduct){
		image = product.imageBanner && product.imageBanner.url
		imageHover =product.imageBanner && product.imageBanner.url
	}
	if(imageIGProduct){
		image = product.imageIG && product.imageIG.url
		imageHover =product.imageIG && product.imageIG.url
	}
	const price = formatMoney(product.price.amount, product.price.currencyCode, true);

	const labels = !product.availableForSale
		? [i18n('products.product.sold_out')]
		: (product.tags.filter((tag) => tag.includes('Label - '))).map(tag => tag.replace('Label - ', ''));

	const Heading = `h${headingLevel || 3}`;

	return (
		<article className={`c-collectionProductV2 ${product.tags.includes("4Ocean") ? "c-collectionProduct-4Ocean" : ""}`}>
			<a
				href={
          getUrl(`/products/${product.handle}${
            (
              !product.handle.includes('-men') &&
              !product.handle.includes('-women') &&
              !product.handle.includes('backpack') &&
              !product.handle.includes('gift-card')
            ) ?
            isMen ?
            '?gender=men' :
            '?gender=women' :
            ''
          }`)}
				class="c-collectionProductV2__link"
				onClick={() => trackProductCLick(product.handle)  }
			>
				<div class="c-collectionProductV2__imageWrapper">
					<div class="c-collectionProductV2__imageSpacer">
						<div class="c-collectionProductV2__imageLayer">
							{videoPLPUrl ?
								<video class="c-collectionProductV2__video" src={videoPLPUrl} autoplay muted loop /> :
								(image && (
									<img
									    src={image}
									    sizes="auto"
									    data-skip-onload-load="true"
									    alt={product.image.altText}
									    loading="lazy"
									    class="c-collectionProductV2__image"
									/>
								))
							}
							
						</div>

						{imageHover && (
							<div class="c-collectionProductV2__imageLayer c-collectionProductV2__imageLayer--hover">
								<img
									src={imageHover}
									sizes="auto"
									data-skip-onload-load="true"
									alt={product.imageHover ? product.imageHover.altText : ""}
									loading="lazy"
									class="c-collectionProductV2__image"
								/>
							</div>
						)}
					</div>

					{product.tags.includes("4Ocean") && (
						<div>
							<div class="c-badge-remove-plastic">
								{ window.plpLanguages?.remove_plastic }
							</div>
						</div>
					)}

				</div>
				{product.handle.includes('gift-card') ? ' ' :
					<div class="c-collectionProductV2__quickView">
						<div class="c-quickView__button"
							onClick={toggelSize}>
							+ { window.plpLanguages?.quick_shop }
						</div>
						<div class="c-quickView__size"
							onClick={selectSize}
							onMouseLeave={ (e) => {toggelSizeOnHoverOut(e)}}>
							<div class="heading">{isMen ? window.plpLanguages?.select_mens_us_size : window.plpLanguages?.select_womens_us_size}</div>
								<div class="sizes">
									{sizes.map((size)=>{
										return <div key={size}  class="size" >
										{size}
									</div>
									})}
								</div>
							</div>
					</div>
				}
				{hideLabel ? '' :
        <div className="c-collectionProductV2__label-group">
          {labels && labels.map((label, index) => (
            (index < 2) ?
              <Fragment>
                <div className="c-collectionProductV2__label">
                  {svgUrl(label) && <img src={svgUrl(label)} alt="" />}
                  <span>{label}</span>
                </div>
              </Fragment>
              : ''
          ))}
        </div>
				}

				<div class="c-collectionProductV2__bottom">
					<div class="c-collectionProductV2__titleWrapper">
						<Heading class="c-collectionProductV2__title">{title}</Heading>
					</div>
					<div className="c-collectionProductV2__subHeadline">{category}</div>

					<div class="c-collectionProductV2__priceWrapper">
						{window.gatedArchiveSale.isEnable() && product.tags.includes('_gated_archive_sale') ?
							(
								<span>
									<span>{formatMoney(product.price.amount - (product.price.amount * (parseInt(window.gatedArchiveSale.discount.value) / 100)), product.price.currencyCode, true, true)}&nbsp;</span>
									<span>
										<span class="visually-hidden">
											{i18n('products.product.regular_price')}
										</span>{' '}
										<s>{price}</s>
									</span>
								</span>
							) :
							product.compareAtPrice.amount > product.price.amount
								? product.priceVaries
									? i18n('products.product.on_sale_from_html', { price })
									: `${i18n('products.product.on_sale')} ${price}`
								: product.priceVaries
								? i18n('products.product.from_text_html', { price })
								: price}
	
							{product.compareAtPrice.amount > product.price.amount && (
								<span>
									<span class="visually-hidden">
										{i18n('products.product.regular_price')}
									</span>{' '}
									<s>{formatMoney(product.compareAtPrice.amount, product.compareAtPrice.currencyCode, true)}</s>
								</span>
							)}
					</div>
				</div>
			</a>
		</article>
	);
};

export default memo(Product);
