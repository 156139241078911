/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact'
import $ from 'jquery'
import { svgUrl } from '../collection/utils'
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import FamilyCrossSell from './FamilyCrossSell'

export default class ImageSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      labels: '',
    }

    this.elRef = createRef()
    this.zoomElRef = createRef()

    this.swiperIns = null
    this.thumbSwiperIns = null

    this.UpdateLabels(this.props)
  }

  componentDidMount() {
    this.zoomHandel()
    this.AddNewSwiper()
    this.customSlider()
    $(window).on('resize', () => {
      this.RemoveSwiper()
      this.AddNewSwiper()
      this.customSlider()
    })
    $(document).on('click', (e) => {
      if ($(e.target).hasClass('product-detail-accordion__item__title')) {
        this.customSlider()
      }
    })
  }

  componentWillUpdate(nextProps, nextState) {
    this.UpdateLabels(nextProps)
    if (this.props.images !== nextProps.images) {
      this.RemoveSwiper()
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.customSlider()
    if (this.props.images !== prevProps.images) {
      this.AddNewSwiper()
      this.zoomHandel()
    }
  }
  shouldComponentUpdate(nextProps) {
    return this.props.images !== nextProps.images
  }

  addOpenModelButton($container) {
    const $button = $(
      `<button class="pdp-image-slideshow__open-model-button${
        this.props.isModelHidden ? ' open-model-button-control' : ''
      }"></button>`,
    )
    $button.on('click', () => {
      if (this.props.onOpenModelButtonClick) {
        this.props.onOpenModelButtonClick()
      }
    })

    $container.append($button)
  }

  customSlider() {
    let gap = 5
    if (window.innerWidth >= 1440) {
      gap = 2
    }
    if (window.innerWidth >= 1024) {
      let offsetEl = $('.c-pdp__grid.c-pdpGrid').offset()
      let marginTopWrapper =
        $('.pdp-slideshow-nav').outerHeight() - $('.c-pdp__gridImg.c-pdpGrid__item').outerHeight() - gap
      $('.c-pdp__gridImg').css({ top: `${offsetEl.top}px` })
      if (marginTopWrapper > 0) {
        $('.shopify-section--product-api').css({ 'padding-bottom': `${Math.abs(marginTopWrapper)}px` })
      } else {
        $('.shopify-section--product-api').css({ 'padding-bottom': 0 })
      }
    } else {
      $('.c-pdp__gridImg').css({ top: 0 })
    }
  }

  UpdateLabels(props) {
    const labels = props.tags
      ? props.tags.filter((tag) => tag.includes('Label - ')).map((tag) => tag.replace('Label - ', ''))
      : ''
    if (labels) {
      this.setState({
        labels: labels,
      })
    }
  }

  RemoveSwiper = () => {
    if (!this.elRef.current) {
      return
    }
    this.swiperIns.destroy()
    this.thumbSwiperIns.destroy()
    $(this.elRef.current).find('.pdp-image-slideshow__open-model-button').remove()
  }
  AddNewSwiper = () => {
    if (!this.elRef.current) {
      return
    }
    const _this = this
    const productSlider = this.elRef.current.querySelector('.pdp-image-slideshow')
    const productSliderNav = this.elRef.current.querySelectorAll('.pdp-slideshow-nav')
    const thumbnailSlider = this.elRef.current.querySelector('.pdp-slideshow-nav .swiper')

    if (productSlider && thumbnailSlider) {
      _this.thumbSwiperIns = new Swiper(thumbnailSlider, {
        modules: [Navigation],
        loop: false,
        slidesPerView: 7,
        speed: 600,
        allowTouchMove: false,
        navigation: {
          nextEl: thumbnailSlider.querySelector('.thumb-button-next'),
          prevEl: thumbnailSlider.querySelector('.thumb-button-prev'),
        },
        breakpoints: {
          1024: {
            direction: 'vertical',
            slidesPerView: 'auto',
            spaceBetween: 2,
          },
          1280: {
            direction: 'vertical',
            slidesPerView: 'auto',
            spaceBetween: 4,
          }
        }
      })

      _this.swiperIns = new Swiper(productSlider, {
        modules: [Navigation, Thumbs],
        loop: true,
        slidesPerView: 1,
        speed: 600,
        navigation: {
          nextEl: productSlider.querySelector('.swiper-button-next'),
          prevEl: productSlider.querySelector('.swiper-button-prev'),
        },
        thumbs: {
          swiper: _this.thumbSwiperIns
        }
      })

      _this.swiperIns.on('slideChange', (swiper) => {
        productSliderNav.forEach((nav) => {
          nav.querySelectorAll('.product-slide').forEach((el) => {
            const currentSlide = swiper.slides[swiper.activeIndex]
            const videoEl = currentSlide.getElementsByTagName('video')[0]
            const thumbnailIndex = parseInt(el.getAttribute('data-index'))

            if (thumbnailIndex === swiper.realIndex) {
              el.classList.add('is-green')
            } else {
              el.classList.remove('is-green')
            }

            if (videoEl) videoEl.play()
          })
        })
      })

      productSliderNav.forEach((nav) => {
        nav.querySelectorAll('.product-slide').forEach((el, index) => {
          el.addEventListener('click', () => {
            _this.swiperIns.slideTo(parseInt(el.getAttribute('data-index')) + 1)
          })

          if (index === 0) el.classList.add('is-green')
          else el.classList.remove('is-green')
        })
      })

      if (this.props.hasModel) {
        this.addOpenModelButton($(this.elRef.current).find('.pdp-image-slideshow'))
      }
    }
  }
  zoomHandel = () => {
    // console.log('called');
    ;(function ($) {
      // console.log('this file is called 2222')
      var defaults = {
        url: false,
        callback: false,
        target: false,
        duration: 120,
        on: 'mouseover', // other options: grab, click, toggle
        touch: true, // enables a touch fallback
        onZoomIn: false,
        onZoomOut: false,
        magnify: 1,
      }

      // Core Zoom Logic, independent of event listeners.
      $.zoom = function (target, source, img, magnify) {
        // console.log('called 1')
        var targetHeight,
          targetWidth,
          sourceHeight,
          sourceWidth,
          xRatio,
          yRatio,
          offset,
          $target = $(target),
          position = $target.css('position'),
          $source = $(source)

        // The parent element needs positioning so that the zoomed element can be correctly positioned within.
        target.style.position = /(absolute|fixed)/.test(position) ? position : 'relative'
        target.style.overflow = 'hidden'
        img.style.width = img.style.height = ''

        $(img)
          .addClass('zoomImg')
          .css({
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: img.width * magnify,
            height: img.height * magnify,
            border: 'none',
            maxWidth: 'none',
            maxHeight: 'none',
          })
          .appendTo(target)

        return {
          init: function () {
            targetWidth = $target.outerWidth()
            targetHeight = $target.outerHeight()

            if (source === target) {
              sourceWidth = targetWidth
              sourceHeight = targetHeight
            } else {
              sourceWidth = $source.outerWidth()
              sourceHeight = $source.outerHeight()
            }

            xRatio = (img.width - targetWidth) / sourceWidth
            yRatio = (img.height - targetHeight) / sourceHeight

            offset = $source.offset()
          },
          move: function (e) {
            var left = e.pageX - offset.left,
              top = e.pageY - offset.top

            top = Math.max(Math.min(top, sourceHeight), 0)
            left = Math.max(Math.min(left, sourceWidth), 0)

            img.style.left = left * -xRatio + 'px'
            img.style.top = top * -yRatio + 'px'
          },
        }
      }

      $.fn.zoom = function (options) {
        return this.each(function () {
          var settings = $.extend({}, defaults, options || {}),
            //target will display the zoomed image
            target = (settings.target && $(settings.target)[0]) || this,
            //source will provide zoom location info (thumbnail)
            source = this,
            $source = $(source),
            img = document.createElement('img'),
            $img = $(img),
            mousemove = 'mousemove.zoom',
            clicked = false,
            touched = false

          // If a url wasn't specified, look for an image element.
          if (!settings.url) {
            var srcElement = source.querySelector('img')
            if (srcElement) {
              settings.url = srcElement.getAttribute('data-src') || srcElement.currentSrc || srcElement.src
            }
            if (!settings.url) {
              return
            }
          }

          $source.one(
            'zoom.destroy',
            function (position, overflow) {
              $source.off('.zoom')
              target.style.position = position
              target.style.overflow = overflow
              img.onload = null
              $img.remove()
            }.bind(this, target.style.position, target.style.overflow),
          )

          img.onload = function () {
            var zoom = $.zoom(target, source, img, settings.magnify)

            function start(e) {
              zoom.init()
              zoom.move(e)

              // Skip the fade-in for IE8 and lower since it chokes on fading-in
              // and changing position based on mousemovement at the same time.
              $img
                .stop()
                .fadeTo(
                  $.support.opacity ? settings.duration : 0,
                  1,
                  $.isFunction(settings.onZoomIn) ? settings.onZoomIn.call(img) : false,
                )
            }

            function stop() {
              $img
                .stop()
                .fadeTo(settings.duration, 0, $.isFunction(settings.onZoomOut) ? settings.onZoomOut.call(img) : false)
            }

            // Mouse events
            if (settings.on === 'grab') {
              $source.on('mousedown.zoom', function (e) {
                if (e.which === 1) {
                  $(document).one('mouseup.zoom', function () {
                    stop()

                    $(document).off(mousemove, zoom.move)
                  })

                  start(e)

                  $(document).on(mousemove, zoom.move)

                  e.preventDefault()
                }
              })
            } else if (settings.on === 'click') {
              $source.on('click.zoom', function (e) {
                if (clicked) {
                  // bubble the event up to the document to trigger the unbind.
                  return
                } else {
                  clicked = true
                  start(e)
                  $(document).on(mousemove, zoom.move)
                  $(document).one('click.zoom', function () {
                    stop()
                    clicked = false
                    $(document).off(mousemove, zoom.move)
                  })
                  return false
                }
              })
            } else if (settings.on === 'toggle') {
              $source.on('click.zoom', function (e) {
                if (clicked) {
                  stop()
                } else {
                  start(e)
                }
                clicked = !clicked
              })
            } else if (settings.on === 'mouseover') {
              zoom.init() // Preemptively call init because IE7 will fire the mousemove handler before the hover handler.

              $source.on('mouseenter.zoom', start).on('mouseleave.zoom', stop).on(mousemove, zoom.move)
            }

            // Touch fallback
            if (settings.touch) {
              $source
                .on('touchstart.zoom', function (e) {
                  e.preventDefault()
                  if (touched) {
                    touched = false
                    stop()
                  } else {
                    touched = true
                    start(e.originalEvent.touches[0] || e.originalEvent.changedTouches[0])
                  }
                })
                .on('touchmove.zoom', function (e) {
                  e.preventDefault()
                  zoom.move(e.originalEvent.touches[0] || e.originalEvent.changedTouches[0])
                })
                .on('touchend.zoom', function (e) {
                  e.preventDefault()
                  if (touched) {
                    touched = false
                    stop()
                  }
                })
            }

            if ($.isFunction(settings.callback)) {
              settings.callback.call(img)
            }
          }

          img.setAttribute('role', 'presentation')
          img.alt = ''
          img.src = settings.url
        })
      }

      $.fn.zoom.defaults = defaults
    })(window.jQuery)
    //  Zoom plugin code for desktop ends here

    // Zoom plugin code for mobile start here
    if (!this.zoomElRef.current || !this.elRef.current) {
      return
    }
    const $el = $(this.elRef.current)
    const $zoomEl = $(this.zoomElRef.current)
    $el.find('.zoom-item').click(function () {
      var imgSrc = $(this).children('img').attr('src')
      $zoomEl.find('.mega-zoom').attr('src', imgSrc)
      // console.log(imgSrc)
      $zoomEl.addClass('overlay-zoom-show')
    })

    $zoomEl.find('.product-page__remove-zoom-button').click(function () {
      $zoomEl.removeClass('overlay-zoom-show')
      $zoomEl.find('.mega-zoom').attr('src', '')
    })
    // Zoom plugin code for mobile ends here

    // Zoom cross icon code for desktop start here
    if ($(window).width() > 1024) {
      var count = 0
      $el.find('.c-pdpSliderImg__item').click(function () {
        // console.log('here123');
        // console.log("ic0ns", $(""))
        if (count == 0) {
          $el.find('.crossIcon').attr('class', 'crossIconShow')
          count = count + 1
        } else {
          $el.find('.crossIconShow').attr('class', 'crossIcon')
          count = count - 1
        }
      })
    }
    // Zoom cross icon code for desktop ends here

    // Zoom plugin script code for desktop start here
    $(document).ready(function () {
      if ($(window).width() > 1024) {
        jQuery('.zoom-item').zoom({ on: 'click' })
      }
    })
    //Zoom plugin script code for desktop ends here
  }
  render() {
    return (
      <Fragment>
        <div class="c-pdp__gridImg c-pdpGrid__item c-pdpSlider" ref={this.elRef}>
          <div class="u-relative">
            <div class="pdp-image-slideshow c-pdpSliderImg swiper">
              <div class="swiper-wrapper">
                {this.props.images.map((image, index) => {
                  if (image.node.originalSrc.includes('video.slideshow')) {
                    return
                  }
                  return (
                    <div class="swiper-slide" key={image.node.originalSrc + index}>
                      <div class={`product-slide c-pdpSliderImg__item zoom-item c-pdpSliderImg__item_${index}`}>
                        <img
                          loading="lazy"
                          src={image.node.originalSrc}
                          data-aspectratio="1.3840830449826989"
                          sizes="auto"
                          width="2000"
                          height="1445"
                          alt=""
                        />
                        <svg class="zoomIcon" width="26" height="23" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fill-rule="evenodd">
                            <g opacity=".7" stroke="#FFF" transform="translate(2 2)">
                              <circle stroke-width="2" fill="#FFF" cx="8" cy="8" r="9"></circle>
                              <path d="M14.5 12.5l6.146 5.121" stroke-width="6" stroke-linecap="round"></path>
                            </g>
                            <g transform="translate(2 2)">
                              <circle stroke="gray" stroke-width="2" cx="8" cy="8" r="7"></circle>
                              <path
                                d="M14.5 12.5l6.146 5.121"
                                stroke="gray"
                                stroke-width="2"
                                stroke-linecap="round"
                              ></path>
                              <path fill="gray" d="M7 5h2v6H7z"></path>
                              <path fill="gray" d="M11 7v2H5V7z"></path>
                            </g>
                          </g>
                        </svg>
                        <svg class="crossIcon" width="22" height="22" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fill-rule="evenodd">
                            <g opacity=".7" stroke="#FFF" stroke-linecap="round" stroke-width="7">
                              <path d="M4 4l14.142 14.142M18.142 4L4 18.142"></path>
                            </g>
                            <g stroke="gray" stroke-linecap="square" stroke-width="3">
                              <path d="M4 4l14.142 14.142M18.142 4L4 18.142"></path>
                            </g>
                          </g>
                        </svg>
                        {index === 0 ? (
                          <div className="c-collectionProductV2__label-group">
                            {this.state.labels &&
                              this.state.labels.map((label, index) =>
                                index < 2 ? (
                                  <Fragment>
                                    <div className="c-collectionProductV2__label">
                                      {svgUrl(label) && <img src={svgUrl(label)} alt="" />}
                                      <span>{label}</span>
                                    </div>
                                  </Fragment>
                                ) : (
                                  ''
                                ),
                              )}
                          </div>
                        ) : (
                          ''
                        )}

                        {image.node.altText?.includes('laces') && (
                          <div class="product-slide__disclaimer">{window.pdpImageDisclaimer?.laces}</div>
                        )}

                        {image.node.altText?.includes('lifestyle') && (
                          <div class="product-slide__disclaimer">{window.pdpImageDisclaimer?.lifestyle}</div>
                        )}
                      </div>
                    </div>
                  )
                })}
                {this.props.video !== null ? (
                  <div class="swiper-slide">
                    <video
                      class="lazyload blur-up"
                      src={this.props.video[0].node.altText}
                      playsinline
                      autoplay
                      muted
                      loop
                    ></video>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div class="swiper-button swiper-button-prev"></div>
              <div class="swiper-button swiper-button-next"></div>
            </div>
            <div class="pdp-slideshow-nav c-pdpSliderNav c-pdpThumbnail-ab c-pdpThumbnail-abFix">
              <div class="swiper">
                <div class="swiper-wrapper">
                  {this.props.images.map((image, index) => {
                    if (image.node.originalSrc.includes('video.slideshow')) return

                    return (
                      <div class="swiper-slide" key={image.node.originalSrc + index}>
                        <div key={image.node.thumbnail} class="product-slide c-pdpSliderNav__item" data-index={index}>
                          <img src={image.node.thumbnail} width="400" height="289" />
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div class="swiper-button thumb-button-prev"></div>
                <div class="swiper-button thumb-button-next"></div>
              </div>
            </div>
            <div class="pdp-slideshow-nav c-pdpSliderNav c-pdpThumbnail">
              {this.props.images.map((image, index) => {
                if (image.node.originalSrc.includes('video.slideshow') && this.props.video == null) {
                  return
                }
                return (
                  <div class="product-slide c-pdpSliderNav__item" data-index={index}>
                    <img src={image.node.thumbnail} width="400" height="289" />
                  </div>
                )
              })}
            </div>
          </div>
          <div class="u-showTabletLargeUp">
            <FamilyCrossSell />
          </div>
        </div>

        <div class="overlay-zoom" ref={this.zoomElRef}>
          <div
            id="ImageWrapper-{{ image.id }}-{{ responsive_image_counter }}"
            data-image-id="{{ image.id }}"
            class="product-page__zoom"
          >
            <img class="mega-zoom product-page__zoom-image" width="2000" height="1445" />
            <button aria-label="close" class="product-page__remove-zoom-button">
              &nbsp;
            </button>
          </div>
        </div>
      </Fragment>
    )
  }
}
